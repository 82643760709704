.footer {
  font-size: 12px;
  margin-top: auto;
  flex-shrink: 0;
  z-index: 2;
}

.footer .row {
  padding: 25px 30px;

  @include respond-to($wide-breakpoint) {
    padding: 25px 15px;
  }

}

.footer .footer-menu {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  white-space: nowrap;

  @include respond-to($large-breakpoint) {
    justify-content: flex-end;
  }

  @include respond-to($mobile-breakpoint) {
    justify-content: flex-start;
  }
}

.footer .flexWrapCont {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  @include respond-to($mobile-breakpoint) {
    display: block;
  }
}

.selectbox-footer {
  margin-right: 30px;

  @include respond-to($large-breakpoint) {
    margin-right: 0;
    margin-bottom: 15px;
   }
}

body[dir="rtl"] {
  .selectbox-footer {
    margin-left: 30px;
    margin-right: unset;
  
    @include respond-to($large-breakpoint) {
      margin-left: 0;
      margin-right: unset;
     }
  }
}

.footer-left {
  align-items: baseline;

  @include respond-to($large-breakpoint) {
   display: block!important;
  }

  #pageLinks, .footer-link-col span {
    @include respond-to($large-breakpoint) {
      display: block!important;
     }
  }

  .footer-link-col span {
    @include respond-to($large-breakpoint) {
      padding: 0;
      padding-bottom: 10px;
     }
  }
}

.disable-click {
  pointer-events: none;
}

.footer .column {
  display: flex;

  span {
    padding-right: 30px;
    font-size: 14px;
    font-family: $font-family-secondary;
  }
}

body[dir="rtl"]{
  .footer .column {
    span {
      padding-left: 30px;
      padding-right: 0px;
    }
  }
}

.footer .footer-menu.appstoreIcons .column {
  padding: 0;
  padding-left: 10px;
}

body[dir="rtl"]{
  .footer .footer-menu.appstoreIcons .column {
    padding: 0;
    padding-right: 10px;
  }
}

.footer .footer-menu.appstoreIcons img {
  height: 40px;
}

.footer .logo {
  display: inline-flex;
  font-size: 20px;
  font-weight: bold;
  white-space: nowrap;
}

.footer .logo .emblem {
  display: inline-block;
  padding: 0 5px;
  font-size: 25px;
  background-color: #fee300;
  color: #000;
  line-height: 30px;
}

.footer .logo .emblem ~ div {
  display: inline-block;
  margin-left: 15px;
  color: #fee300;
  vertical-align: text-bottom;
  letter-spacing: 2px;
  padding-top: 1px;
  margin-right: 20px;
}

.footer .footer-menu .column span {
  cursor: pointer;
}

.footer .footer-menu .column:nth-child(1) {
  padding-left: 0;
}

// RTL 
body[dir="rtl"]{
  .footer .footer-menu .column:nth-child(1) {
    padding-right: 0;
  }
}

.footer .copyright {
  text-align: right;
  margin-top: 10px;
  margin-bottom: 5px;
  font-size: 12px;
  font-family: $font-family-secondary;

  @include respond-to($mobile-breakpoint) {
    text-align: left;
  }
}

body[dir="rtl"]{
  .footer .copyright {
    text-align: left;
    @include respond-to($mobile-breakpoint) {
      text-align: right;
    }
  }
}

.footer select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #333;
  border: 0.5px solid #fee202;
  height: 30px;
  width: 150px;
  border-radius: 5px;
  padding: 0 5px;
}

div.selectbox-footer > div > div:nth-child(1) {
  font-size: 14px;
}

body[dir="rtl"]{
  div.selectbox-footer .selectBox #dropdownIconHolder {
    left:0;
    right: unset;
  }
}

#pageLinks {
  flex-direction: row;

  .column {
    @include respond-to($large-breakpoint) {
      flex-direction: column;

      span {
        margin-bottom: 20px;
      }
    }
  }
}

#footerLeftArea {
  flex-direction: row;
}

#appStoreIconsAndCopyright {
  display: block;
}


@media only screen and (min-width: 768px) and (max-width: 1023px) {

  #appStoreIconsAndCopyright {
    margin-left: auto;
  }

  body[dir="rtl"]{
    #appStoreIconsAndCopyright{
      margin-left: unset;
      margin-right: auto;
    }
  }
}

@media only screen and (min-width: 1024px) {
  #pageLinks {
    flex-direction: row !important;
  }

  #appStoreIconsAndCopyright {
    margin-left: auto;
  }

 body[dir="rtl"]{
  #appStoreIconsAndCopyright{
    margin-left: unset;
    margin-right: auto;
  }
 }
}


.footer__icons {
  display: flex;
  align-items: center;

  @include respond-to($large-breakpoint) {
    display: block;
  }

  .social-icons {
    margin-right: 5px;

    @include respond-to($large-breakpoint) {
      margin-right: 0;
      margin-bottom: 15px;
    }

    .column {
      margin-right: 20px;

      @include respond-to($large-breakpoint) {
        margin-right: 0;
        margin-left: 20px;
      }

      @include respond-to($mobile-breakpoint) {
        margin-right: 20px;
        margin-left: 0;
      }
  

      img {
        width: 40px;
      }
    }
  }
}

body[dir="rtl"]{
  .footer__icons {
    .social-icons{
      margin-left: 5px;
      margin-right: 0px;
      @include respond-to($large-breakpoint) {
        margin-left: 0;
        margin-bottom: 15px;
      }
      .column {
        margin-left: 20px;
        margin-right: 0px;
  
        @include respond-to($large-breakpoint) {
          margin-left: 0;
          margin-right: 20px;
        }
  
        @include respond-to($mobile-breakpoint) {
          margin-left: 20px;
          margin-right: 0;
        }
      }
    }
  }
}