.intro-popup-wrapper {
    max-width: 500px;
    width: 100%;
    overflow: hidden;
    height: auto;
    min-height: 494px;
    box-shadow: 1px 1px 11px 1px #0d0d0d;
    border-radius: 5px;
}

.intro-desc-contents {
    text-align: center;
}

h3.intro-title {
    margin: 15px 15px 0px 15px;
    // color: #ffd200;
    font-size: 18px;
    font-weight: 700;
}

p.intro-desc {
    margin: 0px;
    // height: 35px;
    text-align: center;
    font-family: 'Montserrat';
    font-size: 14px;
    padding: 6px 40px;
    font-weight: 400;
}

.intro-img-container {
    position: relative;
    padding-bottom: 56.2%;
}

img.into-img {
    position: absolute;
    object-fit: cover;
    width: 100%;
    height: 100%;
}

// .pop-up {
//     height: 100%;
// }

.pop-up ul,
.pop-up li {
    margin: 1px;
}
.pop-up .slick-dots li button:before {
    font-size: 8px;
}
// .pop-up .slick-dots li.slick-active button:before {
//     color: #ffd200;
// }
.pop-up .slick-dots li button:before {
    color: #737373;
}

.bottom-nav {
    position: relative;
    bottom: 0px;
    margin: 35px 0px 15px 0px
}

.next-label:hover,
.right-arrow-icon:hover,
.skip-label:hover {
    cursor: pointer;
}


.next-btn,
.skip-btn {
    animation: fadeIn ease 1s;
}

.maybe-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px 32px !important;
    cursor: pointer;
}

span.right-arrow-icon {
    width: 13px;
    margin: -2px 0px 1px 4px
}

// RTL
body[dir="rtl"]{
    span.right-arrow-icon {
        margin: -2px 4px 1px 0px;
        transform: rotateZ(180deg);
    }
}

.pop-up * {
    outline: none;
}

.get-started-btn-container {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn ease 1s;
    // padding: 10px;
}
.login-details-container {
    display: flex;
    align-items: center;
    justify-content: center;
    animation: fadeIn ease 1s;
}

.get-started-btn,.login-btn:hover {
    cursor: pointer;
}

.get-started-btn {
    padding-top: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 14px;
    // background: rgb(255, 215, 26);
    color: rgb(0, 0, 0);
    border: 1px solid rgb(255, 210, 0);
    border-radius: 4px;

    font-family: "Montserrat";
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: 1.14px;
    width: 185px;
    height: 35px;
    box-shadow: 0 0 10px 0 rgba(245, 239, 239, 0.1);
    // position: absolute;
    // top: -15px;
}

@media screen and (max-width: 450px), screen and (max-height: 525px) {
    .intro-popup-wrapper {
        min-height: 400px;
    }
}

@keyframes fadeIn {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
