* {
  box-sizing: border-box;
}

body{
  overflow-y: overlay; // to avoid the page shifting issue depending on the scrollbar 
}

@media tv {
  /* TV-specific styles - adding this to detect tv using media queries*/
  body{
    overflow-y: overlay; // to avoid the page shifting issue depending on the scrollbar 
  }
}

html,
body {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  min-height: 100%;
  min-width: 320px;
  padding: 0;
  margin: 0;
  font-size: 14px;
  max-height: 100%;
}
.ios.mobile{
  body.modal-open{
    position: fixed;
    overflow: hidden;
  } 
}
#root {
  display: flex;
  flex-direction: column;
  height: 100%;
}
.windows{
  #root {
    &.overlay-open{
      overflow: hidden;
      margin-right: 17px;
      .header{
        margin-right: 17px;
      }
    }
  }  
}

.slick-track {
  margin-left: 0 !important;
  margin-right: 0 !important;
}