/* 1% */

/* Extra small devices (portrait phones, less than 576px) */
.details-section-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-bottom: 30px;
}

.details-section-container > div {
  width: 100%;
  height: 100%;
}

.favourite-processing {
  svg {
    cursor: default!important;
  }
}

.details-section-container .details-section-player {
  cursor: pointer;
  position: relative;
  padding-top: 56%;
  height: auto;
  overflow: auto;
}

.details-section-container .details-section {
  position: relative;
}

.details-section-container .details-section-player img,
.details-section-container .details-section-player-play-icon {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
}

@media only screen and (max-width: 480px) {
  .detailsSection__title {
    font-size: 22px;
  }

  .detailsSection__subtitle {
    font-size: 14px;
    padding-bottom: 2px;
  }
}

.details-section-container .details-section .details-section-favourites {
  margin-left: auto;
  padding-left: 15px;
}

.details-section-container .details-section-body::-webkit-scrollbar {
  width: 0 !important;
  display: none;
}

.details-section-container .details-section .details-section-body {
  position: relative;
  flex: 1 1 auto;
  padding-bottom: 15px;
}

.details-section-container .details-section .details-section-body .details-section-rating-container {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-rating-container
  > div:not(:first-child) {
  margin-left: 30px;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-rating-container
  .details-section-parental-rating {
  width: 80px;
  height: 30px;
  border: 1px solid #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-section-container .details-section-player-play-icon,
.details-section-container .details-section-release-year,
.details-section-container .details-section-rating {
  display: flex;
  align-items: center;
  justify-content: center;
}

.details-section-container .details-section .details-section-body .details-section-details,
.details-section-container .details-section .details-section-body .details-section-overview-container {
  margin: 0 0 30px 0;
  font-size: $font-size-secondary + 4px;
}

.details-section-container .details-section .details-section-body .details-section-overview-title {
  border-bottom: #ffe300 1px solid;
  padding-bottom: 5px;
  margin-bottom: 15px;
  font-weight: bold;
  font-size: 1.1em;
}

.details-section-container .details-section .details-section-body .details-section-details-row {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-title {
  min-width: 125px;
  text-align: left;
  padding-right: 15px;
  font-weight: bold;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-descriptions
  a {
  text-decoration: none;
}

.details-section-container
  .details-section
  .details-section-body
  .details-section-details-row
  .details-section-details-description
  a:hover {
  text-decoration: underline;
}

/* +70% */

/* Large devices (desktops, 992px and up) */
@media (min-width: 960px) {
  .details-section-container {
    flex-direction: row;
  }

  .details-section-container > div {
    width: 50%;
    height: none;
  }

  .details-section-container .details-section-player {
    padding-top: 28%;
    order: 2;
  }

  .details-section-container .details-section {
    padding-top: 28%;
    height: 100%;
    order: 1;
  }

  .details-section-container .details-section .details-section__media,
  .details-section-container .details-section .details-section__sports {
    width: 100%;
    height: 100%;
    top: 0;
    position: absolute;
  }
}

@media (max-width: 960px) {

  .full-width-container {
    
    .full-width-details-section {
      display: none;
    }

    .normal-details-section {
      display: block;
    }
  }
}


@media (min-width: 960px) {

  .full-width-container {
    padding: 0px;
    max-width: 100vw;
    overflow-x: hidden;

    .full-width-details-section {
      display: block;
    }

    .purchase-rail-item button {
      text-transform: uppercase;
    }

    .details-section-body__content {
      display: flex;
    }

    .details-section-btn__action .action__single p {
      font-size: 18px;
      font-weight: 700;
    }

    .releaseYear {
      display: none;
    }

    .layout {
      position: relative;
      margin: 0 auto;
      max-width: 1440px;
    }

    .detailsSection__title {
      font-size: 32px;
      font-weight: 700;
    }

    .details-section-body .description {
      font-size: 16px;
    }

    .normal-details-section {
      display: none;
    }
    .details-section-container {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
      padding: 0px;
    }

    .details-section-body {
      display: flex;
    }

    .details-section___skeleton-wrapper {
      display: none;
    }

    .details-section-btn__action {
      flex-direction: column;
      margin: 0px 20px 20px 0px;

      .action__single {
        margin-right: 0px;
        margin-bottom: 15px;
      }
    }

    .details-section-header__badges ul {
      flex-direction: row-reverse;
      align-items: center;

      li {
        margin-top: 0px;
        padding: 2px 4px;
        font-size: 14px;
      }

      li:nth-child(2) {
        padding: 2px 4px;
        font-size: 14px;
      }
    }

    .inApp-purchase-wrapper {
      margin: 0 auto;
      max-width: 1440px;
      padding: 20px 5px 10px 30px;
      position: relative;
    }

    .details-section-header__badges span:first-child {
      font-size: 16px;
    }
  
    .details-section-player {
      height: calc(100vh - 70px);
      width: 100%;
  
      .gradient-wrapper {
        background: linear-gradient( to bottom, rgba(24, 24, 24, 1) 0%, rgba(24, 24, 24, 0) 17%, rgba(24, 24, 24, 0) 50%, rgba(24, 24, 24, 1) 100% );
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 2;
        top: 0;
        left: 0;
      }
  
      .img-holder__img-container {
        border-radius: 0px;
      }
  
      .player {
        border-radius: 0px;
      }
    }

    .details-section-body__header {
      margin-right: 15px;
    }

    .details-section-header {
      position: absolute;
      top: 2%;
    }
  
    .details-section {
      width: 98%;
      position: absolute;
      bottom: 0;
      z-index: 2;
      margin-left: auto;
      margin-right: auto;
      left: 50px;
      right: 0;
      max-width: 1440px;
  
      .details-section__media {
        height: unset;
        top: unset;
        bottom: 0;

        .details-section-body h3 {
          margin-left: 10px;
          font-size: 14px;
        }
      }
    }
  }

  .landscape-boxed-container {
    max-width: 1109px;

    .layout {
      position: relative;
    }  

    .purchase-rail-item button {
      text-transform: uppercase;
    }
    
    .details-section {
      margin-left: unset;
      width: 100%;
      position: relative;
      padding-top: 10px;

      .details-section__media {
        position: unset;
      }
    }  

    .details-section-container {
      display: flex;
      flex-direction: column-reverse;
      position: relative;
    }

    .details-section-player {
      height: 600px;
      width: 100%;
    }

    .details-section-btn__action .action__single p {
      font-size: 18px;
      font-weight: 700;
    }
    
    .details-section-header__badges {
      margin-top: 15px;
    }
    
    .details-section-body .description {
      font-size: 16px;
    }

    .details-section__media .details-section-body h3 {
      font-size: 14px;
    }

    .details-section__media-content {
      padding: 30px 10px 0 0;
    } 

    .details-section__media {
      z-index: 1;
    } 

    .inApp-purchase-wrapper {
      padding: 30px 10px 10px 30px;
      position: relative;
    }

    .details-section-body {
      max-width: 80%;
    }

    .layout {
      position: relative;
    }

    .details-section__media .details-section-header .detailsSection__title {
      font-size: 32px;
      font-weight: 700;
    }

    .releaseYear {
      display: none;
    } 

    .details-section-header__badges ul li {
      display: none;
    }

    .details-section-header__badges span:first-child {
      font-size: 18px;
      margin-top: 15px;
    }
  }

  .portrait-boxed-container {
    padding: 45px 0 100px;
    position: relative;
    max-width: 1290px;

    .layout {
      position: relative;
    }  

    .details-section-container {
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
    }

    .purchase-rail-item button {
      text-transform: uppercase;
    }
     
    .details-section-player {
      width: 400px;
      border-radius: 15px;
    }

    .inApp-purchase-wrapper {
      padding: 30px 10px 10px 30px;
      position: relative;
    }

    .details-section-btn__action .action__single {
      box-shadow: unset;

      p {
        font-weight: 600;
        font-size: 18px;
      }
    }

    .details-section {
      width: 60%;
      margin-left: 0px;
    }

    .details-section__media {
      z-index: 1;

      .details-section-header {
        .detailsSection__title {
          font-size: 40px;
          font-weight: 700;
        }
      }
    }

    .playerContainer .img-holder__img-container {
      transform: rotate(-90deg);
      height: 100%;
      width: 100%;
    }

    .details-section-body {
      .description {
        font-size: 16px;
      }
    }

    .details-section__more-info-link {
      font-size: 15px;
    }

    .details-section-header__badges {
      margin-top: 20px;
      margin-bottom: 10px;

      ul {
        flex-direction: row-reverse;
        align-items: center;

        li {
          margin-top: 5px;
          font-size: 14px;
          padding: 2px 4px;
        }

        .releaseYear {
          display: none;
        }

        .duration {
          font-size: 20px;
          font-weight: 600;
          margin-left: 10px;
        }
      }
    }
  }

  .alt-original-container {
    .details-section-container {
      flex-direction: row-reverse;
      position: relative;
    }

    .purchase-rail-item button {
      text-transform: uppercase;
    }

    .details-section-header__badges ul {
      margin-top: 8px;    
      li {
        margin-top: 12px;
      }
    }

    .releaseYear {
      display: none;
    }

    .layout {
      position: relative;
    }

    .details-section-body {
      margin-top: 0px;
    }

    .details-section-body .description {
      font-size: 16px;
    }

    .details-section__media .details-section-body h3 {
      font-size: 14px;
    }

    .details-section-player {
      width: 50%;
    }
    
    .details-page__content .details-section__media-content {
      padding: unset;
    }

    .details-section__media > div {
      padding-right: 0px;
    }

    .inApp-purchase-wrapper {
      padding: 40px 10px 10px 30px;
      position: relative;
    }

    .layout {
      margin: 70px auto;
      position: relative;
    }
  }
  // .details-section {
  //   margin-left: 30px;
  // }

  .details-section-header__badges {
    ul {
      flex-direction: column;
      align-items: flex-start;

      div:nth-child(2)::before {
        width: unset;
        height: unset;
      }

      li {
        margin-top: 10px;
      }
    }
  }
}
.sharePopUp {
  > div {
    max-width: 380px !important;
  }

  .details-page__pop-up-head {
    text-align: left;
    align-items: baseline!important;
    padding: 15px 40px 7px!important;

    p {
      font-family: $font-family-secondary;
      font-weight: 600;
      font-size: 14px;
      line-height: 14px;
      padding-bottom: 3px;
    }

    h1 {
      font-family: $font-family;
      margin: 0;
      font-size: 26px;
      line-height: 26px;
    }
  }

  .sharePopUp__content {
    padding: 0 40px 15px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin-top: 20px;


    .single:not(:last-child){
      margin-bottom: 20px;
    }
    .single:last-child{
      margin-bottom: 25px;
    }

    .single {
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      transition: all 0.2s ease-in-out;
      font-family: $font-family-secondary;
      font-weight: 600;
      button {
        display: flex;
        align-items: center;
        width: 100%;
        justify-content: center;
        height: 46px;

        &:focus {
          outline: none;
        }

        p {
          margin: 0;
          font-size: 16px;
          padding-right: 13px;
        }
      }

      &:hover {
        cursor: pointer;
        transform: scale(1.05);
      }
    }

    .facebook {
      background: #3a5896;
    }

    .twitter {
      background: #00acee;
    }

    .normal {
      background: #ffd200;
      padding: 14px;
      height: 46px;

      // @include respond-to($wide-breakpoint) {
      //   font-size: 18px;
      // }

      p {
        color: black;
        margin: 0;
        font-size: 14px;
        font-weight: 600;
      }
    }
  }
}

// RTL 
body[dir="rtl"]{
  .sharePopUp {
    .details-page__pop-up-head {
      text-align: right;
    }
    .sharePopUp__content {
      .single {
        button {
          p {
            padding-left: 13px;
            padding-right: unset;
          }
        }
      }
    } 
  }
}


.details-page___line {
  border-bottom: 3px solid rgb(51, 51, 51);
}