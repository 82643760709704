@import '../settings/typography';

/*--------------------------------------------------------------------*/
/* THIS IS A PROJECT SPECIFIC FILE - AFTER MODIFICATION COPY THE FILE
 TO RESPECTIVE DEFAULT FOLDER BEFORE RE-RUNNING THE SERVER */
/*--------------------------------------------------------------------*/

.contact-us {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1 0 auto;
  height: 100%;
  padding: 30px;

  .title {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .title__logo {
      width: 500px;
      height: 100px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    .title__description {
      font-size: 16px;
      font-weight: normal;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
    }
  }

  .button-container {
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;

    .button-container__title {
      font-size: 16px;
      font-weight: bold;
      font-style: normal;
      font-stretch: normal;
      line-height: normal;
      letter-spacing: 0.3px;
      text-align: center;
      margin-bottom: 8px;
    }

    .button-container__button {
      button {
        font-family: $font-family;
        margin-bottom: 8px;
        font-size: 18px;
        font-style: normal;
        font-stretch: normal;
        line-height: 22px;
        letter-spacing: 0.3px;
        text-align: center;
        padding: 14px 8px;
        cursor: pointer;
        border: none;
        outline: none;
        transition: 0.3s;
        font-weight: 700;
        border-radius: 5px;
        display: inline-block;
        width: 208px;
        text-transform: uppercase;

        &:not(:last-child) {
          margin-bottom: 50px;
        }
      }

      &:not(:last-child) {
        margin-bottom: 15px;
      }
    }
  }
}
.elements__WidgetFooter{
  display: none;
}

.elements__PoweredByWrapper-h3m622-6 {
  display: none;
}

#freshworks-frame-wrapper {
  right: calc(50% - 172px)!important;
  top: 180px !important;
  height: calc(100% - 230px) !important;
  margin-top: 30px;
} 

.feedback-form {
  display: flex;
  flex-direction: column-reverse;
  width: 100%;
  height: 180px;
  position: relative;
  .title__logo {
    width: 100px;
    background-size: contain;
    background-repeat: no-repeat;
    position: absolute;
    justify-content: center;
    align-items: center;
    right: calc(50% - 50px);
  }
}
