.cms-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  z-index: 9;

  .cms-page__content-wrapper {
    max-width: 650px;
    width: 100%;
    display: flex;
    align-items: flex-end;

    .content::-webkit-scrollbar {
      width: 0 !important;
    }

    .cms-page__content-head {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 20px 30px;
      border-radius: 5px 5px 0 0;

      @include respond-to(700px) {
        padding: 20px 20px 12px;
      }
    }

    .cms-page__content {
      width: 100%;
      height: 80%;
      .content {
        position: relative;
        border-radius: 5px 5px 0 0;
        flex-shrink: 0;
        height: -webkit-fill-available;
        .cms-page__content-data {
          max-height: 70vh;
          flex-shrink: 0;
          height: 100%;
          width: 100%;
          overflow: auto;
        }

        p {
          font-family: $font-family-secondary;
          font-size: $font-size-secondary + 4px;
          font-weight: $regular;
          margin: 0 0 20px;
        }
      }

      .heading {
        margin: 0;
        font-size: $font-size + 11px;

        @include respond-to(700px) {
          font-size: $font-size + 7px;
        }
      }

      .close-bnt {
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}

.footer-popup-open {
  .cms-page__content-wrapper {
    -webkit-animation: slide-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    animation: slide-in-bottom 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

.maintance-page {
  height: 100vh;
  min-height: 100vh;
  position: fixed;
  width: 100%;
  overflow: auto;
  z-index: 9;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0;

  .player & {
    position: relative;
    height: 100%;
    min-height: 100%;
  }

  .maintance-page-img {
    width: 100%;
    max-width: 140px;
    height: 160px;
    background-size: contain;
    background-position: top;
    margin: 0 auto;
    background-repeat: no-repeat;
  }

  .maintance-page__content {
    max-width: 315px;

    h2 {
      margin: 32px 0 0;
      font-size: $font-size + 5px;
    }

    p {
      font-family: $font-family-secondary;
      margin: 10px 0;
      font-size: $font-size-secondary + 4px;
    }

    .error-code {
      margin-bottom: 80px;
    }

    img {
      width: 100%;
      max-width: 200px;
      margin: 0 auto;
    }

    button {
      padding: 11px;
      width: 100%;
      border-radius: 5px;
      border: none;
      font-size: $font-size;
      font-weight: bold;
      font-style: normal;
      max-width: 170px;

      &:focus,
      &:hover {
        outline: none;
        cursor: pointer;
      }
    }
  }
}

.my-account-menulist {
  max-width: 600px;
  margin: 0 auto;
  width: 100%;
  padding: 20px;

  ul {
    li {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 30px 0;

      &:last-child {
        border: none;
      }

      .text-box-wrapper {
        display: flex;
        align-items: center;
        min-height: 36.8px;
        word-break: break-word;

        .text {
          padding-left: 20px;

          h3,
          p {
            margin: 0;
          }

          p {
            font-size: $font-size + 2;
            font-weight: 400;
            line-height: 21px;
          }

          h3 {
            font-size: $font-size + 4;
            font-weight: $medium + 100;
          }
        }
      }

      .action {
        margin: 0;
        font-size: $font-size + 2;
        font-weight: $medium;
        padding-left: 10px;
        min-width: 100px;
        text-align: right;
      }
    }
  }
}

body[dir="rtl"]{
  .my-account-menulist {
    ul{
      li{
        .text-box-wrapper {
          .text{
            padding-right: 20px;
            padding-left: unset;
          }
        }
        .action {
          padding-left: unset;
          padding-right: 10px;
          text-align: left;
        }
      }
    }
  }
}

.no-networkpage {
  background: rgb(31, 31, 31);
}

@-webkit-keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slide-in-bottom {
  0% {
    -webkit-transform: translateY(1000px);
    transform: translateY(1000px);
    opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}

.cms-page__content-data {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
}
