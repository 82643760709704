.searchIcon {
  display: flex;

  &:hover {
    cursor: pointer;
  }
}

.search-heading {
  font-size: 18px;
  margin: 12px 0 20px;
  font-weight: bold;
}

.search-bar {
  @include respond-to($large-breakpoint) {
  }
}

.searchbar-res {
  display: none;
  align-items: baseline;

  .back-btn {
    display: none;
  }

  @include respond-to($large-breakpoint) {
    display: flex;
    justify-content: flex-end;
  }
}

.search-bar__input {
  position: relative;
  padding: 6px 20px 6px 20px;
  display: flex;
  align-items: center;
  width: 320px;
  height: 45px;
  border-radius: 5px;
  z-index: 1;
  font-family: $font-family-secondary;
  box-shadow: 0 0 0px 0 rgba(0, 0, 0, 0.25);
  transition: all 50ms linear;

  @include respond-to($large-breakpoint) {
    width: 0;
    height: 0;
    padding: 0;
    transition: none;
  }

  svg {
    width: 18px;

    @include respond-to($large-breakpoint) {
      width: 0;
    }
  }

  ::-webkit-input-placeholder {
    /* Edge */
    color: #a6a6a6;
    font-family: $font-family-secondary;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #a6a6a6;
    font-family: $font-family-secondary;
  }

  ::placeholder {
    color: #a6a6a6;
  }
  .search-box-form{
    width: 100%;
  }
  .textbox {
    font-size: $font-size-secondary + 4px;
    line-height: normal;
    padding: 0 0 0 0;
    border: none;
    box-shadow: none;
    font-family: $font-family-secondary;
    border-radius: 0;
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;

    &:focus {
      outline: none;
    }
    &:focus ~ .floting-label {
      top: 7px;
      font-size: 10px;
    }
    .label-focused {
      top: 7px;
      font-size: 10px;
    }
  }
  .textbox:focus::placeholder {
    color: transparent;
  }

  .textbox:not(:placeholder-shown) ~ .floting-label {
    top: 7px;
    font-size: 10px;
  }

  .textbox::-ms-clear {
    display: none;
  }
  .floting-label {
    display: none;
  }
}

.search-bar__suggestion {
  position: absolute;
  width: 320px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  background-color: #000000;
  top: 55px;
  height: auto;
  max-height: calc(100vh - 55px);
  overflow-y: auto;

  .suggestion-title {
    display: inline-flex;
    width: 100%;

    .search-heading {
      &:first-child {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        padding-right: 5px;
      }
    }
  }

  .suggestion-list {
    padding: 20px;

    // .suggestion-title {
    //   &:first-child {
    //     white-space: nowrap;
    //     overflow: hidden;
    //     text-overflow: ellipsis;
    //   }
    // }

    @include respond-to($large-breakpoint) {
      display: none;
    }

    .spinner-main-wrapper {
      padding: 5px;
    }

    .show-more-label {
      margin: 0;
      padding: 20px 0 0;
      letter-spacing: 1.33px;
      text-align: center;
      text-transform: uppercase;
      font-size: 14px;
      display: block;
      cursor: pointer;
    }

    .suggestion-list-item-bottom {
      span {
        font-size: 13px;
        font-family: $font-family-secondary;
        position: relative;
        padding-right: 16px;
        text-transform: capitalize;

        &::after {
          content: "";
          position: absolute;
          width: 4px;
          height: 4px;
          top: 8px;
          right: 6px;
          border-radius: 2px;
          background: #a6a6a6;
        }

        &:last-child {
          &::after {
            display: none;
          }
        }
      }
    }

    .search-heading {
      margin: 0;
      font-size: 12px;
      font-family: $font-family-secondary;
      text-transform: uppercase;
      letter-spacing: 1px;

      &.clear-all {
        margin-left: auto;
        cursor: pointer;
      }
    }

    ul {
      margin-top: 15px;
      overflow-y: scroll;

      -webkit-overflow-scrolling: touch;
      -ms-overflow-style: none;
      overflow: -moz-scrollbars-none;
      scrollbar-width: none;

      &::-webkit-scrollbar {
        display: none !important;
        width: 0 !important;
      }
    }

    li {
      cursor: pointer;
      margin: 0 0 10px;

      &:last-child {
        margin: 0;
      }
    }

    .subtitle {
      display: block;
      font-size: 16px;
    }

    .title {
      display: block;
      font-size: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .match-list {
    ul {
      padding: 15px 0;
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;

      li {
        margin-right: 15px;
      }
    }
  }
}

.search-bar__input---focus {
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  transition: all 50ms linear;

  .floting-label{
    display: block;
    margin: 0;
    font-size: 10px;
  }

  @include respond-to($large-breakpoint) {
    transition: none;
  }
}

.responsiveSearchOpen {
  transition: none;
  @include respond-to($large-breakpoint) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;

    .search-bar__input {
      position: absolute;
      width: calc(100% - 30px);
      height: 45px;
      left: 0;
      top: 13px;
      margin: 0 15px;
      padding: 6px 20px 7px 20px;
      z-index: 2;
      background: #1a1a1a;
      padding-left: 40px;

      svg {
        width: 18px;
      }
    }

    .suggestion-list {
      display: block;
      position: relative;
      z-index: 1;
    }

    .back-btn {
      display: block;
      position: absolute;
      left: 26px;
      top: 23px;
      z-index: 3;
    }

    .search-bar__suggestion {
      left: 0;
      width: 100vw;
      height: calc(100vh - 55px);
      max-height: calc(100vh - 55px);
      border-radius: 0;
      overflow-y: auto;
    }

    .search-bar__input---focus {
      background: white;
    }
  }
}

body[dir="rtl"]{
  .responsiveSearchOpen {
    @include respond-to($large-breakpoint) {
    right: 0;
    left: unset;
  
      .search-bar__input {
        right: 0;
        left: unset;
        padding-right: 40px;
        padding-left: 20px;
      }
  
      .back-btn {
        right: 26px;
        left: unset;
        transform: rotateZ(180deg);
      }
  
      .search-bar__suggestion {
        right: 0;
        left: unset;
      }
    }
  }
}

.search-bar__suggestion {
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
}

.textbox::-webkit-credentials-auto-fill-button {
  visibility: hidden;
}
