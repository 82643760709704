.faq-page {
  max-width: 700px;
  margin: 0 auto;
  background-color: grey;
}

.faq-page .faq_page_title_wrapper {
  font-size: 1.8rem;
  font-weight: bold;
  margin: 15px auto 15px 25px;
}

.faq-container,
.purchase-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1 0 auto;
  height: 100%;
  z-index: 1;
  font-family: $font-family-secondary ;

  .faq {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    justify-content: center;
    width: 100%;

    &:not(:last-child) {
      padding-bottom: 10px;
    }

    .faq__title {
      width: 100%;
      text-align: center;
      // padding: 25px;
      font-weight: bold;
      font-size: 18px;
    }

    .faq__body {
      width: 100%;

      .item {
        width: 100%;
        padding: 35px 0;
        text-align: left;
        font-size: 15px;

        &:hover {
          cursor: pointer;
        }

        .item__title {
          padding-right: 25px;
          font-weight: bold;
          display: flex;
          flex-direction: row;
          align-items: center;

          .item__title-icon {
            .item__title-icon--0deg {
              animation: rotate-0-cw 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

              @keyframes rotate-0-cw {
                0% {
                  transform: rotate(45deg);
                }

                100% {
                  transform: rotate(90deg);
                }
              }
            }

            .item__title-icon--45deg {
              animation: rotate-45-cw 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

              @keyframes rotate-45-cw {
                0% {
                  transform: rotate(0);
                }

                100% {
                  transform: rotate(45deg);
                }
              }
            }

            display: flex;
            flex-direction: column;
            margin: 0 25px;
            cursor: pointer;
          }
        }

        .item__description {
          margin-left: 63px;
          padding-top: 25px;
          padding-right: 25px;

          &.item__description--show {
            animation: flip-in-hor-bottom 1s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;

            @keyframes flip-in-hor-bottom {
              0% {
                height: 0;
                opacity: 0;
              }

              100% {
                height: 100%;
                opacity: 1;
              }
            }
          }

          &.item__description--hide {
            animation: flip-out-hor-top 0.45s cubic-bezier(0.55, 0.085, 0.68, 0.53) both;

            @keyframes flip-out-hor-top {
              0% {
                transform: rotateX(0);
                opacity: 1;
                display: none;
              }

              100% {
                transform: rotateX(70deg);
                opacity: 0;
                display: none;
              }
            }
          }
        }
      }
    }
  }
}

body[dir="rtl"]{
  .faq-container{
    .faq{
      .faq__body {
        .item {
          text-align: right;
          .item__title {
            padding-left: 25px;
            padding-right: unset;
          }
          .item__description {
            margin-right: 63px;
            margin-left: unset;
            padding-left: 25px;
            padding-right: unset;
          }
        }
      }
    }
  }
}