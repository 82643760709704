.multi-profile-list-popup__body {
    position: relative;

    .spinner-wrapper {
        position: absolute;
        width: 100%;
        height: 100%;
        padding-top: 60px;
        z-index: 20;
    }
}

.multi-profile-list-popup__body__profile-list {
    max-height: 76vh;
    min-height: 76vh;
    overflow: auto;
}

.indivudual-profile-single {
    border-bottom: solid 1px rgba(114,114,114,  0.25);

    &:last-child {
        border: none;
        margin-bottom: 100px;
    }

    &:hover {
        cursor: pointer;
    }

    &:first-child {
        border-top: solid 1px rgba(114,114,114,  0.25);
    }
}

.multi-profile-list-popup__body__profile-list__buttons {
    padding: 20px 40px;

    div {
        cursor: pointer;
    }

    .multi-profile-list-popup__body__profile-list__buttons__create-new {
        font-size: 14px;
        font-weight: 700;
        padding: 17px;
        text-align: center;
        border-radius: 5px;
    }
    .create-profile-button {
        width: 40%;
        margin: 0 auto;
        @include respond-to($mobile-breakpoint) {
            width: 85%;
        }
    }
}

.profile-details__header__title {
    font-size: 22px;
    font-weight: 600;
}

.multi-profile-list-popup__body__profile-list__buttons__update-pin {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    margin: 20px 0 50px;
    color: rgba(255, 232, 41, 1);
}

.multi-profile-list-popup__header {
    padding: 28px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    // border-bottom: solid 1px #3f3f3f;
    border-bottom: solid 1px rgba(114,114,114, 0.25);
   
    svg {
        cursor: pointer;
    }

    .multi-profile-list-popup__header__title {
        font-size: 24px;
        font-weight: 600;
    }
}
.select-profile-title{
    width: 25%;
    margin: auto;
    font-size: 14px;
    font-weight: 400;
    padding: 20px 0;
    @include respond-to($mobile-breakpoint) {
        width: 50%;
    }
}

.profile-details-wrapper {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 5;
    overflow: auto;
    display: flex;
    align-items: center;

    .profile-details-main {
        max-width: 500px;
        padding: 50px 20px 50px;
        box-sizing: content-box;
        @include respond-to($mobile-breakpoint) {
            max-width: 300px;
        }
    }
}

.indivudual-profile-wrapper__name {
    font-size: 20px;
    font-weight: 500;
}

.avatar-collection-wrapper {
    padding: 20px;
    .avatar-collection-container {
        max-width: 600px;
        background-color: #2b2b2b;
        position: fixed;
        z-index: 9;
        max-height: 95vh;
        overflow: scroll;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
        &::-webkit-scrollbar {
            width: 0 !important;
            display: none;
        }
        .avatar-collection-container__header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 25px 40px 0;

            .avatar-collection-container__header__title {
                font-size: 24px;
                font-weight: 600;
            } //avatar-collection-container__header__title

            .avatar-collection-container__header__close {
                cursor: pointer;
            } //avatar-collection-container__header__close
        } //avatar-collection-container__header

        .avatar-list {
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            margin-top: 30px;
            padding: 0 20px 5px 40px;

            .avatar-full {
                width: 110px;
                margin-top: 13px;
                .avatar-wrapper {
                    margin-top: 20px;
                }
            }
        }

        .avatar-list___single {
            margin: 0 20px 20px 0;

            &:hover {
                cursor: pointer;
            }
        }
    }
}

.indivudual-profile-wrapper__edit-icon {
    .profile-icon---hover {
        display: none;
    }

    .profile-icon---normal {
        display: block;
    }

    &:hover {
        .profile-icon---hover {
            display: block;
        }

        .profile-icon---normal {
            display: none;
        }
    }
}
