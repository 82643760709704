/* 1% */

/* Extra small devices (portrait phones, less than 576px) */
.app-wrapper {
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  flex-shrink: 0;
}

// RTL
body[dir="rtl"]{
  .app-wrapper {
    overflow-x: hidden;
  }
}

.visibility-hidden {
  visibility: hidden;
}

.d-none {
  display: none;
}

// added following lines to fix "page content visible only after click in safari 10" (https://diagnal.atlassian.net/browse/DC-4194)
// solution found here: https://stackoverflow.com/a/43835935/11189335
@keyframes forceRedraw {
  from {
    box-shadow: inset rgba(0, 0, 0, 0) 0 0 0;
  }
  to {
    box-shadow: inset rgba(0, 0, 0, 0.0000001) 0 0 0 10px;
  }
}
// -------------------

.catalogContainer {
  flex: 1 1 auto;
  flex-direction: column;
  flex-shrink: 0;
  // position: relative;

  // added following lines to fix "page content visible only after click in safari 10" (https://diagnal.atlassian.net/browse/DC-4194)
  // solution found here: https://stackoverflow.com/a/43835935/11189335
  animation-name: forceRedraw;
  animation-duration: 4s;
  animation-iteration-count: infinite;
  // -------------------

  .layout {
    max-width: $max-width;
    width: 100%;
    margin: 0 auto;
    padding-bottom: 0;

    .grid {
      padding: 0 30px;
      padding-right: 15px;
      padding-top: 20px;
      padding-bottom: 15px;

      @include respond-to($wide-breakpoint) {
        padding: 0 20px;
        padding-top: 10px;
        padding-right: 12px;
        padding-bottom: 5px;
      }

      @include respond-to($medium-breakpoint) {
        padding: 0 15px 0;
        padding-right: 7px;
        padding-bottom: 5px;
      }
    }
  }
}

// RTL
body[dir='rtl'] {
  .catalogContainer {
    .layout {
      .grid {
        padding: 0 30px;
        padding-left: 15px;
        @include respond-to($wide-breakpoint) {
          padding: 0 20px;
          padding-left: 12px;
        }

        @include respond-to($medium-breakpoint) {
          padding: 0 15px 0;
          padding-left: 7px;
        }
      }
    }
  }
}

.layout__header {
  padding: 0 30px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;

  @include respond-to($wide-breakpoint) {
    padding: 0 20px;
  }

  @include respond-to($medium-breakpoint) {
    padding: 0 15px 10px;
  }

  .layout__title {
    padding: 0;
  }
}

.page-content___wrapper {
  padding-bottom: 10px;
  flex-shrink: 0;
}
