/* ------------------------------------*\
    #FONTS
\*------------------------------------ */
@import '../tools/mixins';

/**
 * Font stacks
 */

 $font-family               : Montserrat,sans-serif,Helvetica,Arial;
 $font-family-secondary     : Montserrat,sans-serif,Helvetica,Arial;
 $font-size                 : 12px;
 $font-size-secondary       : 10px;
 $header-logo-height        : 49px;
 $max-width                 :1440px !default;
 $rail-title-font-size      : 22px;
 $rail-viewAll-font-size    : 18px;
 $rail-itemName-font-size   : 14px;

* {
    --font-size-secondary: 12px;
}

// font weight
$light:300;
$regular: 400;
$medium: 500;
$bold:700;
