.sidebar {
  -webkit-touch-callout: none;

  /* iOS Safari */
  -webkit-user-select: none;

  /* Safari */
  -khtml-user-select: none;

  /* Konqueror HTML */
  -moz-user-select: none;

  /* Firefox */
  -ms-user-select: none;

  /* Internet Explorer/Edge */
  user-select: none;

  /* Non-prefixed version, currently supported by Chrome and Opera */
  visibility: hidden;

  .sidebar__menu-header {
    display: flex;
    align-items: center;
    padding: 30px 0 5px;
    justify-content: center;

    img {
      width: 130px;
    }
  }
}

.sidebar__btn-set {
  padding-bottom: 15px;

  .sign-up {
    font-weight: 600;
    padding: 13px;
    display: block;
    text-align: center;
    max-width: 180px;
    margin: 0 auto;

    &:hover {
      cursor: pointer;
    }
  }

  p {
    padding: 15px 5px;
    display: block;
    border-radius: 30px;
    text-align: center;
    max-width: 180px;
    margin: 0 auto;
    font-weight: 600;

    a {
      color: white;
      border-bottom: 1px solid white;
      padding: 0 1px;
    }
  }
}

.no-scroll-reserve-gap {
  margin-right: 17px !important;
}

.no-scroll-no-reserve-gap {
  margin-right: 0px !important;
}

.header {
  svg {
    &:hover {
      cursor: pointer;
    }
  }
}


.sidebar__overlay {
  transition: all 0.45s ease-in;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  opacity: 0;
  z-index: -1;
  background-color: rgba(0, 0, 0, 0.7);
}

.sidebar__menu-links {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  // min-height: calc(100% - 135px);
  padding: 30px 0;
}

.sidebar__profile-item {
  display: flex !important;
  align-items: flex-end;
  margin-left: -10px;
  span {
    margin-bottom: 5px;
    margin-left: 8px;
  }
}

body[dir="rtl"]{
  .sidebar__profile-item {
    margin-right: -10px;
    margin-left: unset;
  }
  span {
    margin-left: unset;
    margin-right: 8px;
  }
}

.header__profile-item {
  align-items: center;
  display: flex;

  // img {
  //   width: 23px;
  //   border: 1.5px solid;
  //   border-radius: 50px;
  // }
}

.sidebar__menu {
  z-index: 9999;
  width: 380px;
  -webkit-transition: left 0.6s ease-in-out;
  transition: left 0.6s ease-in-out;
  position: fixed;
  left: -380px;
  top: 0;
  bottom: 0;
  white-space: nowrap !important;
  overflow: -moz-scrollbars-vertical;
  overflow: -moz-scrollbars-none;
  overflow: hidden;
  overflow-y: auto !important;
  -ms-overflow-style: none;
  overscroll-behavior: none;
  scrollbar-width: none;

  @include respond-to(1440px) {
    width: 320px;
    left: -320px;
  }

  @include respond-to(768px) {
    width: 285px;
    left: -285px;
    white-space: nowrap !important;
    overflow-y: auto !important;
    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
  }

  ul {
    li {
      margin-bottom: 20px;
      position: relative;

      .sidebar-account-nametag {
        position: absolute;
        right: 15px;
        top: 5px;
        border-radius: 50px;
        text-align: center;
        height: 45px;
        width: 45px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-transform: uppercase;
        font-size: 16px;
      }

      &:last-child {
        margin: 0;
      }

      a,
      div {
        font-size: $font-size + 5px;
        display: block;
        padding: 15px 20px;
        font-weight: 500;
        transition: background-color 0.2s ease-in-out;
        position: relative;
        border: none;

        &.mobile-only {
          display: none;

          @include respond-to($large-breakpoint) {
            display: block;
          }
        }

        &::before {
          content: '';
          position: absolute;
          height: 100%;
          width: 25px;
          left: 0;
          top: 0;
          background-position: center;
          background-size: contain;
          background-repeat: no-repeat;
        }

        &:hover {
          cursor: pointer;
        }
      }

      .active {
        &::after {
          content: '';
          position: absolute;
          height: 100%;
          width: 5px;
          left: 0;
          top: 0;
          border-radius: 0 5px 5px 0;
        }
      }
    }
  }
}

body[dir="rtl"]{
  .sidebar__menu{
    -webkit-transition: right 0.45s ease-in-out;
    transition: right 0.45s ease-in-out;
    right: -380px;
    left: unset;
  
    @include respond-to(1440px) {
      width: 320px;
      right: -320px;
      left: unset;
    }
  
    @include respond-to(768px) {
      width: 285px;
      right: -285px;
      left: unset;
    }
  
    ul {
      li {
        .sidebar-account-nametag {
          left: 15px;
          right: unset;
        }
        a,
        div {
          &::before {
            right: 0;
            left: unset;
          }
        }
  
        .active {
          &::after {
            right: 0;
            left: unset;
          }
        }
      }
    }
  }
}

.sidebar__menu::-webkit-scrollbar {
  width: 0 !important;
}

.sidebar--open {
  .sidebar__overlay {
    z-index: 9998;
    position: fixed;
    opacity: 1;
    -webkit-transition: opacity 0.45s;
    transition: opacity 0.45s;
  }

  .sidebar__menu {
    display: block;
    z-index: 9999;
    overflow: hidden;
    left: 0;
    top: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    visibility: hidden;
    @include respond-to($large-breakpoint) {
      visibility: visible;
    }
  }
}

body[dir="rtl"]{
  .sidebar--open {
    .sidebar__menu {
      left: unset;
      right: 0;
    }
  }
}

.header___primary-nav {

  @include respond-to($large-breakpoint) {
    display: none;
  }

  ul {
    list-style: none;
    display: flex;
    background: transparent;

    li {
      margin-right: 20px;

      &:last-child {
        margin: 0;
      }

      a {
        font-weight: 500;
        font-size: $font-size + 3px;
        padding: 0 5px;
      }

      .active {
        position: relative;

        &:after {
          content: '';
          position: absolute;
          left: 0;
          bottom: -23px;
          height: 5px;
          width: 100%;
          border-radius: 100px 100px 0 0;
        }
      }
    }
  }
}

body[dir="rtl"]{
  .header___primary-nav{
    ul {
      li {
        margin-right: 0px;
        margin-left: 20px;
      }
    }
  }
}


.header__menuIcon---desktop {
  @include respond-to($large-breakpoint) {
    display: none;
  }
}

.header__menuIcon---responsive {
  display: none;

  @include respond-to($large-breakpoint) {
    display: block;
  }
}

.header__profile-wrapper {
  position: relative;

  @include respond-to($large-breakpoint) {
    display: none;
  }
}

.header__profile-list {
  position: absolute;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
  right: 0;
  top: 56px;
  min-width: 173px;
  transition: opacity 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
  opacity: 0;
  height: 0;
  display: none;
  transition: height 200ms cubic-bezier(0.4, 0.0, 0.2, 1);

  ul {
    height: 0;
    transition: height 200ms cubic-bezier(0.4, 0.0, 0.2, 1);
    padding: 23px;
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;

    /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
    li, a {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      &:last-child {
        margin: 0;
      }

      h3 {
        margin: 0;
        margin-left: 20px;
        font-size: $font-size + 3px;
        font-weight: 500;
      }


      &:hover {
        cursor: pointer;
      }

      .header__profile-item{
        .avatar-wrapper{
          img{
            border: 2px solid
          }
        }
      }

    }
  }
}

body[dir="rtl"]{
  .header__profile-list{
    left: 0;
    right: unset;
    ul {
      li, a {
        h3 {
          margin: 0;
          margin-right: 20px;
        }
      }
    }
  }
}

.header__profile-list---open {
  opacity: 1;
  transition: opacity 200ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1);
  height: auto;
  transition: height 200ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1);
  display: block;
  z-index: 6;
  ul {
    height: auto;
    transition: height 200ms 50ms cubic-bezier(0.4, 0.0, 0.2, 1);
  }
}

.header__profile-btn {
  margin-left: 30px;

  .header__profile-head-img {
    padding: 7px;
    border-radius: 5px;

    // img {
    //   width: 25px;
    //   border: 1.5px solid;
    //   border-radius: 50px;
    // }
  }

  &:hover {
    cursor: pointer;
  }
}

body[dir="rtl"]{
  .header__profile-btn {
    margin-right: 30px;
    margin-left: 0px;
  }
}

.settings-popUp {

  .details-page__pop-up-wrapper {
    padding: 30px 30px 20px!important;

    @include respond-to($wide-breakpoint) {
      padding: 20px!important;
    }
  }

  .details-page__pop-up-head {
    margin-bottom: 25px !important;
  }

  .settings-popUp__content {
    margin-bottom: 30px;

    .action-single {
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-align: left;

      p {
        font-family: $font-family-secondary;
        font-size: $font-size-secondary + 4px;
        font-weight: 600;
        margin: 0;
        padding-right: 15px;
        white-space: normal;
      }
    }
  }

  body[dir="rtl"]{
    .settings-popUp__content {
      .action-single {
        p {
          padding-left: 15px;
          padding-right: unset;
        }
      }
    }
  }
}


/* 1% */

/* Extra small devices (portrait phones, less than 576px) */
.header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;

  /* width: 100%; */
  white-space: nowrap;
  display: flex;
  align-items: center;
  z-index: 1000;
}

img {
  display: none;
}

img[src] {
  display: block;
}

.header,
.gostHeader {
  min-height: 75px;
  height: 75px; // when coming back from playerback page the header heigth was not working properly
  z-index: 5;
}

.headerLogo {
  width: auto;
  height: $header-logo-height;
  // margin: 0 15px;
  margin-right: 15px; // VERTICAST Specific

}

.header__title {
  margin-right: auto;
  font-size: $font-size + 5px;
  font-weight: bold;
}

body[dir="rtl"]{
  .header__title {
    margin-right: 0;
    margin-left: auto;
  }
}

.header__goBackArrowIcon {
  margin-right: 15px;
}

.header__goBackArrowIcon,
.header__menuIcon {
  cursor: pointer;
  margin-right: 30px;
}

body[dir="rtl"]{
  .header__goBackArrowIcon,
.header__menuIcon {
    margin-right: 0px;
    margin-left: 30px;
    transform: rotateZ(180deg);
}
}
.landingHeader {
  position: fixed;
  display: flex;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 0px;
}
.header-section-btn__action {
  margin: 14px 0px 16px;
  display: flex;
  flex-shrink: 0;
  position: absolute;
  right: 0;

  .action__single {
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    padding: 12px 22px;
    margin-right: 25px;
    align-items: center;

    @include respond-to(375px) {
      padding: 12px 10px 13px;
      margin-right: 10px;
    }

    .hover {
      display: none;
    }

    &:hover {
      cursor: pointer;

      .normal {
        display: none;
      }

      .hover {
        display: block;
      }
    }

    p {
      margin: 0;
      font-weight: 600;
      font-size: $font-size + 1px;

      @include respond-to(375px) {
        padding-left: 5px;
      }
    }
  }
}

body[dir="rtl"]{
  .header-section-btn__action  {
    right: unset;
    left: 0;
    .action__single {
      margin-left: 25px;
      margin-right: unset;
    }
  }
}

@media screen and (max-width:400px) {
  .headerLogo {
    max-width:100%;
    margin: 0;
  }
  .header__menuIcon {
    margin-right: 20px; // VERTICAST Specific
  }
}
