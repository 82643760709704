.layout__title {
  display: inline-flex;
  margin-right: auto;
  font-size: $rail-title-font-size;
  font-weight: 600;

}

// RTL
body[dir="rtl"]{
  .layout__title {
    margin-left: auto;
    margin-right: unset;
  }
}

.slick-center {
  z-index: 1;
  position: relative;
}

.layout__more-link {
  font-size: $rail-viewAll-font-size;
  font-weight: 500;
  display: inline-flex;
  cursor: pointer;
}

.layout__body {
  position: relative;

  .slick-slider .slick-slide > div {
    padding: 20px 0;
    display: block;

    @include respond-to($medium-breakpoint) {
      padding: 0 0 20px;
    }
  }

  .slick-dots {
    bottom: 0px;

    @include respond-to($wide-breakpoint) {
      bottom: 10px;
    }

    @include respond-to($medium-breakpoint) {
      bottom: 10px;
    }

    li {
      @include respond-to($medium-breakpoint) {
        margin: 0;
      }
    }

    li button:before {
      opacity: 1;
      content: "";
      border-radius: 50%;
      height: 8px;
      width: 8px;
      top: 5px;
      left: 5px;
    }

    li.slick-active button:before {
      opacity: 1;
    }
  }
}

.layout__body .rail {
  font-size: 0;
  white-space: nowrap !important;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
  padding: 0 30px;
  overflow: hidden;

  @include respond-to($wide-breakpoint) {
    padding: 0 20px;
  }

  @include respond-to($medium-breakpoint) {
    padding: 0 15px;
  }

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
}

.layout__body .grid {
  padding: 0 0 0 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

.layout__footer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 12px;

  & > div,
  & > div.active {
    height: 7px;
    width: 7px;
    border-radius: 7px;
    margin-right: 15px;
    cursor: pointer;

    &:last-child {
      margin-right: 0;
    }
  }
}

.overflow-arrow {
  position: absolute;
  font-size: 0;
  z-index: 3;
  top: 20px;
  bottom: 0;
  cursor: pointer;
  text-align: center;
  display: flex;
  align-items: center;
}

.overflow-arrow--left {
  left: -3px;
  // background: rgba(0, 0, 0, 1);
  // background: -moz-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0) 100%);
  // background:
  //   -webkit-gradient(
  //     left top,
  //     right top,
  //     color-stop(0%, rgba(0, 0, 0, 1)),
  //     color-stop(100%, rgba(19, 19, 19, 0))
  //   );
  // background: -webkit-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0) 100%);
  // background: -o-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0) 100%);
  // background: -ms-linear-gradient(left, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0) 100%);
  // background: linear-gradient(to right, rgba(0, 0, 0, 1) 0%, rgba(19, 19, 19, 0) 100%);
  // background-image: linear-gradient(to right, #2b2b2b, rgba(43, 43, 43, 0));
  // filter: progid:dximagetransform.microsoft.gradient(startColorstr='#000000', endColorstr='#131313', GradientType=1);
  text-align: left;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center;
}

.overflow-arrow--right {
  right: 0;
  // background: -moz-linear-gradient(left, rgba(19, 19, 19, 0) 0%, rgba(0, 0, 0, 1) 100%);
  // background:
  //   -webkit-gradient(
  //     left top,
  //     right top,
  //     color-stop(0%, rgba(19, 19, 19, 0)),
  //     color-stop(100%, rgba(0, 0, 0, 1))
  //   );
  // background: -webkit-linear-gradient(left, rgba(19, 19, 19, 0) 0%, rgba(0, 0, 0, 1) 100%);
  // background: -o-linear-gradient(left, rgba(19, 19, 19, 0) 0%, rgba(0, 0, 0, 1) 100%);
  // background: -ms-linear-gradient(left, rgba(19, 19, 19, 0) 0%, rgba(0, 0, 0, 1) 100%);
  // background: linear-gradient(to right, rgba(19, 19, 19, 0) 0%, rgba(0, 0, 0, 1) 100%);
  // background-image: linear-gradient(to left, #2b2b2b, rgba(43, 43, 43, 0));
  // filter: progid:dximagetransform.microsoft.gradient(startColorstr='#131313', endColorstr='#000000', GradientType=1);
  text-align: right;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: center;
}

.overflow-arrow--hide {
  display: none !important;
}
