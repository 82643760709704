/* ------------------------------------*\
    #PAGE
\*------------------------------------ */

/**
 * 1. Ensure the page is always at least the height of the viewport
 * 2. Ensure OSX fonts will look more consistent with other OSes that do not
 *    utilize sub-pixel anti-aliasing.
 * 3. Ensure browsers will not automatically zoom fonts.
 */

* {
  box-sizing: border-box;
}

.container-fluid {
  max-width: 100%;
  width: 100%;
}

body {
  background-color: #000;
  font-family: $font-family;
  position: relative;
}

svg {
  vertical-align: middle;
}


.main-container {
  width: 100%;
  margin: 0 auto;
  position: relative;
  // max-width: 1440px; // VERTICAST Specific

  .header & {
    display: flex;
    align-items: center;
    padding: 15px 30px;

    @include respond-to($medium-breakpoint) {
      padding: 15px 15px;
    }
  }
}