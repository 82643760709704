// .rail-slider {
//     padding-top: 15px;
//     .rail-slider__single {
//         &:focus {
//             outline: none;
//         }
//         .rail-slider__single-content {
//             background-color: $darkgrey;
//             border-radius: 3px;
//             height: 100%;
//             margin: 0 8px;
//             min-height: 300px;
//         }
//     }
// }

.rail-container {
  white-space: nowrap !important;
  overflow-x: auto !important;
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  scrollbar-width: none;
}

.rail-container::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  background-color: transparent;
}

.rail-container::-webkit-scrollbar-track {
  //    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.1);
  background-color: transparent;
}

.rail-container::-webkit-scrollbar-thumb {
  //    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.3);
  background-color: transparent;
}

.rail-track {
  padding: 15px;
  display: flex;
  flex-wrap: nowrap;
  flex-direction: row;
  overflow-x: auto;
}

.rail-track::-webkit-scrollbar {
  display: none !important;
  width: 0 !important;
  background-color: transparent;
}

.rail-item {
  width: 150px;
  border-radius: 3px;

  .rail__imgHolder {
    position: relative;
    width: 150px;
    background: $darkgrey;
    height: 200px;
    border-radius: 3px;
    overflow: hidden;

    .on-hover {
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(255, 227, 0, 0.55);
      opacity: 0;
      -webkit-transition: 0.75s;
      -o-transition: 0.75s;
      transition: 0.75s;

      &:hover {
        opacity: 1;
      }

      a {
        display: block;
      }
    }
  }

  &:not(:last-child) {
    margin-right: 15px;
  }
}

.layout-item {
  // overflow: hidden;

  .img-holder {
    position: absolute;
    background-attachment: local;
    background-size: cover;
  }

  .layout-item__top-title {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    width: 100%;

    @include respond-to(700px) {
      min-height: 20px;

      .label-only {
        .rail & {
          font-size: 14px;
          white-space: normal;
          -webkit-line-clamp: 1;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;
          display: block;
        }
      }
    }
  }
}

.rail-item-info-wrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000);
  cursor: pointer;
}

.rail-item-info___head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 45px 8px 10px;

  svg {
    position: relative;
    z-index: 2;
    &:hover {
      cursor: pointer;
    }
  }

  p {
    margin: 0;
    font-size: $font-size-secondary + 4px;
    font-weight: 500;
    font-family: $font-family-secondary;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-left: 10px;
    display: block;
    overflow: hidden;
  }
}

.rail-item-info___progress {
  position: relative;
  width: 100%;
  background: #2b2b2b;
  height: 2px;

  &::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
  }
}

body[dir="rtl"]{
  .rail-item-info___progress {
    &::after {
      left: unset;
      right: 0;
    }
  }
}
