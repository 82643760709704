@import "../../../styles/settings/typography";

.error-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 15px;

  .error-popup-header-close-btn {
    text-align: right;
    padding-bottom: 15px;
    margin-right: -15px;
    svg {
      cursor: pointer;
    }
  }

  .error-popup__content {
    text-align: center;
    border-radius: 4px;
    padding: 20px 40px;
    max-width: 337px;
    width: 100%;

    h2 {
      margin: 0;
      font-size: 22px;
    }

    p {
      white-space: normal;
      margin: 10px 0 20px;
      font-family: $font-family-secondary;
    }
  }

  .popup-btn-subtitle {
    font-size: 14px;
    margin-top: 25px;
    font-family: $font-family-secondary;
  }

  .popup-btn {
    padding: 10px 20px;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 16px;
    width: 100%;

    &:hover {
      cursor: pointer;
    }

    svg {
      margin-right: 8px;
    }
  }

  .popup-btn__negative {
    margin-top: 15px;
  }

  .error-popup__btn,
  .error-popup__faq {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .error-popup__btn {
    margin-top: 20px;
    padding: 0 26px;
    .error-popup-wrapper---inline-action & {
      flex-direction: row;
    }
  }

  .error-popup__faq {
    margin-top: 25px;

    .popup-btn-subtitle {
      font-size: 12px;
      margin-top: 0;
    }

    .popup-btn__negative {
      margin-top: 10px;
    }
  }
}
