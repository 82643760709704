.meta-data-preview-overlay {
  position: absolute;
  z-index: 10;
  border-radius: 5px;
  overflow: hidden;
  &.has-progress{
    border-bottom-left-radius: 0;
    border-bottom-right-radius:0 ;
  }
  &.hero-rail {
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    // background-color: rgba(51, 51, 51, 0.7);
    margin: auto;
    .meta-data-preview__image{
      opacity: 1;
    }
  }
}

.meta-data-preview {
  background-color: black;
  box-shadow: 5px 5px 10px 10px rgba(0, 0, 0, 0.25);
  z-index: 5;
  width: 100%;
  height: 100%;
  margin: auto;
  position: absolute;
  animation: fadeIn ease 1s;

  .player__bottom-controls,
  .player__top-controls {
    display: none;
  }
  &.hero-rail {
    border-radius: 5px;
    overflow: hidden;
    .meta-data-preview__player-container {
      height: 100%;
    }
    .volume-btn-wrapper {
      position: absolute;
      bottom: 20px;
      right: 20px;
      cursor: pointer;
    }
    .meta-data-preview__details {
      position: absolute;
      width: 100%;
      bottom: 0;
      z-index: 3;
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.0001) 0%,
        #000000 100%
      );
    }
  }
  .volume-btn-wrapper {
    position: absolute;
    right: 20px;
    bottom: 155px;
    z-index: 1;
    background-color: #222222;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .meta-data-preview__details {
    padding: 20px;
  }
  .meta-data-preview__icons {
    display: flex;
  }
  .meta-data-preview__icon {
    margin: 0 8px;
    cursor: pointer;
    &:first-child {
      margin-left: 0;
    }
    .play-icon-filled,
    .info-icon-filled,
    .fav-icon-filled {
      display: none;
    }
    &:hover {
      .play-icon,
      .info-icon,
      .fav-icon {
        display: none;
      }
      .play-icon-filled,
      .info-icon-filled,
      .fav-icon-filled {
        display: block;
      }
    }
    .fav-icon.is-fav {
      display: none;
    }
    .fav-icon-filled.is-fav {
      display: block;
    }
  }
  .meta-data-preview__title {
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px;
    letter-spacing: 0px;
    text-align: left;
    margin-top: 16px;
    margin-bottom: 10px;
  }
  .meta-data-preview__player-container {
    position: relative;
    width: 100%;
    height: calc(100% - 150px);
    border-radius: 5px;
    overflow: hidden;
  }
  .meta-data-preview__image {
    width: 100%;
    position: absolute;
    z-index: -1;
    left: 0;
    top: 0;
    max-height: 100%;
    object-fit: contain;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
  }
  .meta-data-preview__description-container {
    padding: 20px;
    position: relative;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 21px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    height: 100%;
    &.has-description{
      background-color: rgba(0, 0, 0, 0.8);
    }

    .meta-data-preview__description {
      margin: 0;
      display: -webkit-box;
      -webkit-line-clamp: 9;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }
  .rail-item-info___progress {
    position: absolute;
    width: 100%;
    background: #727272;
    height: 4px;
    left: 0;
    bottom: 0;

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 4px;
    }
  }

  // RTL
  body[dir="rtl"] {
    .rail-item-info___progress {
      left: unset;
      right: 0;
      &::after {
        left: unset;
        right: 0;
      }
    }
  }

  .duration {
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: left;
    color: rgba(255, 255, 255, 1);
    display: inline-block;
    margin: 0;
    margin-right: 12px;
  }
  .remaining-time {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0px;
    text-align: right;
    color: rgba(255, 255, 255, 1);
    display: inline-block;
    margin: 0;
  }
  .meda-data-preview__bottom-Info {
    display: flex;
    justify-content: space-between;
  }
  .meta-data-preview__tags {
    display: inline-flex;
    li {
      margin: 0 7px;
      font-size: 14px;
      font-weight: 600;
      line-height: 17px;
      color: rgba(151, 151, 151, 1);
      span {
        position: relative;
      }
      span::before {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        top: 8px;
        left: -8px;
        border-radius: 2px;
        background-color: rgba(151, 151, 151, 1);
      }
      &:first-child {
        margin-left: 0;
        span::before {
          display: none;
        }
      }
    }
  }
}
.not-playing {
  opacity: 0;
}
.playing {
  opacity: 1;
}
.fade-in {
  animation: fadeIn ease 0.5s;
}
.fade-out {
  animation: fadeOut ease 0.5s;
}
.scale-animation{
  animation: scale ease 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes scale {
  0% {
    transform: scale(0.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
