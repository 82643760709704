.selectBox {
  line-height: 30px;
}

.selectBox .selectContainer {
  display: inline-block;
}

.selectBox #input {
  position: relative;
  width: 150px;
  min-height: 15px;
  line-height: 15px;
  border-radius: 5px;
  padding: 10px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
}

.selectBox #dropdownIconHolder {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

// RTL
body[dir="rtl"]{
  .selectBox #dropdownIconHolder {
    left: 0;
    right: unset;
  }
 .react-date-picker__calendar {
   right: 0 !important;
   left: unset !important;
 }
}

.selectBox #dropdownIcon {
  position: absolute;
  width: 0;
  height: 0;
  top: 50%;
  margin-top: -2px;
  border-width: 5px 5px 0 5px;
  border-style: solid;
}

.selectBox #dropdownHolder {
  position: relative;
  width: 100%;
  z-index: 1;
}

.selectBox #dropdown {
  position: absolute;
  left: 0;
  width: 100%;
  border-radius: 5px;
  background-color: #222;
  overflow: hidden;
  overflow-y: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none !important;
    width: 0 !important;
  }
}

.selectBox #dropdownHolder.above #dropdown {
  bottom: 5px;
}

.selectBox #dropdownHolder.below #dropdown {
  top: 5px;
}

.selectBox #dropdown > div {
  padding: 10px;
  cursor: pointer;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  line-height: 15px;
}

.selectBox #dropdown > div:first-child {
  border-radius: 5px 5px 0 0;
}

.selectBox #dropdown > div:last-child {
  border-radius: 0 0 5px 5px;
}

.selectBox #dropdown > div:hover {
  background-color: #FFE300;
}

.selectBox #dropdown > div:not(:last-child) {
  border-bottom: 2px solid #333;
}

.selectBox .show {
  display: block;
}

.selectBox .hide {
  display: none;
}

.selectBox .invisible {
  visibility: hidden;
}
