/* ------------------------------------*\
    #Button
\*------------------------------------ */


.button {
    width: 100%;
    text-align: center;
    line-height: normal;
    padding: 0;
    font-size: 14px;
    text-transform: initial;
    background-color: transparent;
    border-radius: 5px;
    height: 56px;
    color: transparent;
    border: 0;
    cursor: pointer;
    font-family: $font-family;
  }
  
  .button,
  .button:focus {
    outline: 0;
  }
  