@import "../../styles/settings/typography";
@import "../../styles/tools/mixins";

* {
  box-sizing: border-box;
}

$font-family-secondary: "Open Sans", sans-serif, Helvetica, Arial;

tspan {
  // font-size: 7px:
}

.playerContainer {
  display: flex;
  flex: 1 1 auto;
  background-color: #000;
  top: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 5;
  overflow: hidden;

  .player__top-controls,
  .player__bottom-controls,
  .player-audio__content-wrapper,
  .player-settings__content-wrapper {
    -webkit-user-select: none; /* Chrome all / Safari all */
    -moz-user-select: none; /* Firefox all */
    -ms-user-select: none; /* IE 10+ */
    user-select: none;
  }
}

.playerContainer .header__title {
  font-size: 0.48em;
}

.playerContainer > * {
  width: 100%;
  border: 0;
}

.tpPlayer .header,
.tpPlayer .gostHeader {
  right: auto;
  bottom: auto;
}

button.tpPlayOverlay {
  display: none !important;
}

.tpPlayOverlay:not(button) {
  background-color: transparent !important;
  width: auto !important;
  height: auto !important;
}

.player-audio__list {
  background: rgba(31, 31, 31, 0.85);
  padding: 20px;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;

  .player-audio__content-list-wrapper {
    margin-top: 10px;
    display: flex;
    padding: 0 30px 30px 40px;

    .player-audio__content {
      width: 100%;
    }

    h3 {
      margin: 0;
      padding-right: 30px;
      width: 100%;
      max-width: 120px;
      font-family: $font-family-secondary;
      text-align: left;
      font-size: $font-size-secondary + 4px;
      margin-top: 10px;
      color: white;
    }
  }

  > div {
    max-width: 410px;
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    border-radius: 5px;
  }

  p {
    margin: 0;
    font-size: $font-size + 7px;
    font-weight: 600;
  }

  ul {
    margin: 0;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    width: 100%;

    li {
      font-size: $font-size-secondary + 4px;
      font-weight: 500;
      margin: 12px 7px 0;
      font-family: $font-family-secondary;
      border-radius: 5px;
      width: calc(50% - 14px);
      line-height: 20px;
      height: 42px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }

    .active {
      &:hover {
      }
    }
  }

  .player-audio__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 40px 0;

    p {
      .chrome-cast-controller & {
        color: #ffd200;
      }
    }
  }
}

.player-audio__list---open {
  opacity: 1;
  z-index: 9;

  .player-audio__content-wrapper {
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }

  .player-audio__content {
  }
}

.player-audio__list---close {
  display: none;
}

.player-settings__list {
  background: rgba(31, 31, 31, 0.85);
  padding: 20px;
  position: absolute;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  opacity: 0;
  z-index: -1;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;

  .player-settings__content {
    // padding: 0 20px 30px 40px;
  }

  .player-settings__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    // padding: 30px 40px 0;
  }

  > div {
    max-width: 520px;
    width: 100%;
  }

  p {
    margin: 0;
    font-size: $font-size + 7px;
    font-weight: 600;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
    flex-wrap: wrap;

    li {
      font-size: $font-size-secondary + 4px;
      font-weight: 500;
      margin: 20px 20px 0;
      margin-left: 0;
      font-family: $font-family-secondary;
      border-radius: 5px;
      width: calc(33.333% - 20px);
      line-height: 20px;
      height: 42px;
      padding: 0 18px;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        cursor: pointer;
      }
    }
  }
}

body[dir="rtl"]{
  .player-settings__list {
    ul {
      li {
        margin: 20px 0 0 20px;
      }
    }
  }
}

.player-error-container {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: black;

  a,
  button {
    font-weight: 500;
    padding: 8px 14px 9px;
    border-radius: 4px;
    width: 70px;
    margin: 0 auto;
    display: block;
    font-size: $font-size;
    border: none;

    &:hover {
      cursor: pointer;
    }

    &:focus {
      outline: none;
    }
  }

  > div {
    max-width: 250px;
  }

  h2 {
    margin: 10px 0 20px;
    font-size: $font-size + 7px;
  }
}

.player {
  top: 0;
  height: 100%;
  z-index: 1;
  position: absolute;
  width: 100%;
  overflow: hidden;
  display: flex;
  background: black;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  @media only screen and (max-width: 992px) {
    border-radius: 0;
    box-shadow: none;
  }

  video {
    width: 100%;
  }

  .seek__track {
    .slider-tracks {
      div {
        background: #ffd200;
      }
    }
  }

  .player__content {
    width: 100%;
    height: 100%;
    background: black;

    iframe {
      z-index: -1;
      position: relative;
    }
  }

  svg {
    &:hover {
      cursor: pointer;
    }
  }

  &:hover {
    cursor: default;
  }
}

.player__bottom-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  left: 0;
  display: block;
  z-index: 5;
  opacity: 0;
  padding: 0px 20px 10px;
  background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 68%);
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  
  @media screen and (min-width: 1200px) {
    padding: 100px 20px 30px;
  }
  .startover-btn__action {
    margin: 22px 0 20px;
    display: flex;
    flex-shrink: 0;
  
    .action__single {
      border-radius: 5px;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
      display: flex;
      padding: 14px 0 14px;
      padding-left: 12px;
      padding-right: 12px;
      margin-right: 12px;
      align-items: center;
  
      @include respond-to(375px) {
        padding: 12px 10px 13px;
        margin-right: 10px;
      }
  
      &:last-child {
        margin: 0;
      }
  
      .hover {
        display: none;
      }
  
      &:hover {
        cursor: pointer;
  
        .normal {
          display: none;
        }
  
        .hover {
          display: block;
        }
      }
  
      p {
        margin: 0;
        padding-left: 10px;
        font-weight: 700;
        font-size: $font-size + 2px;
  
        @include respond-to(375px) {
          padding-left: 5px;
        }
      }
    }
  }

  .controls {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    margin-bottom: 20px;
    position: relative;

    @media screen and (min-width: 1200px) {
      margin-bottom: 60px;
    }

    .rewind-icon {
      margin-top: 2px;
    }
    .forward-icon {
      margin-top: 2px;
    }
    .next-button {
      display: none;
      margin-top: 2px;
    }
    .fast-rewind-icon {
      display: none;
      margin-top: 2px;
    }
    .fast-forward-icon {
      display: none;
      margin-top: 2px;
    }
    .previous-icon {
      display: none;
      margin-top: 2px;
    }
    .player-icon {
      display: block;
    }
    .playerfill-icon {
      display: none;
    }

    .player-icon-container:hover .player-icon {
      display: none;
    }

    .player-icon-container:hover .playerfill-icon {
      display: block;
    }

    > div {
      margin: 0 30px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }
    }
  }

  .seek-bar {
    flex: 1;
    position: relative;

    .seek-time {
      display: flex;
      align-items: center;
      padding-top: 20px;

      @media screen and (min-width: 1200px) {
        padding-top: 30px;
      }

      p {
        font-family: $font-family-secondary;
        margin: 0;
        font-weight: 600;
        font-size: $font-size-secondary + 6px;
      }
    }
  }
}

// RTL 
body[dir='rtl']{
  .player__bottom-controls{
    .seek-bar {
      .seek-time {
        float: right;
      }
    }
  }
}

// .player__overlay{
//   position: absolute;
//   width: 100%;
//   height: 100%;
//   left: 0;
//   top: 0;
// }

.player__top-controls {
  position: absolute;
  top: 0;
  width: 100%;
  left: 0;
  opacity: 0;
  display: flex;
  align-items: center;
  padding: 20px 30px 20px;
  background-image: linear-gradient(
    to top,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.8)
  );
  justify-content: flex-end;
  -webkit-transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  transition: opacity 0.25s cubic-bezier(0, 0, 0.2, 1);
  z-index: 5;

  .cast_caf_state_c {
    // --connected-color: rgb(255, 210, 0);
  }

  .player__top-titile-wrapper {
    flex: 1;
    display: flex;
    align-items: center;

    .back__icon-bottom-margin {
      margin-bottom: 0.8%;
    }

    .title {
      padding-left: 20px;

      p {
        margin: 0;
        font-size: $font-size-secondary + 5px;
        white-space: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        font-weight: normal;
        font-family: $font-family-secondary;

        span {
          padding-right: 20px;
          position: relative;
          &:after {
            content: "";
            position: absolute;
            width: 4px;
            height: 4px;
            top: 9px;
            right: 8px;
            border-radius: 2px;
          }

          &:last-child {
            &:after {
              display: none;
            }
          }
        }
      }

      h2 {
        margin: 0;
        font-size: $font-size + 11px;
        white-space: normal;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
      }
    }
  }

  .controls {
    display: flex;
    align-items: flex-end;

    .control-icons-wrapper {
      height: 30px;
      justify-content: center;
      display: flex;
      align-items: center;
      margin-top: 3px;
    }

    .control-icons {
      display: block;
    }

    .control-icons-filled {
      display: none;
    }

    .control-icons-wrapper:hover .control-icons {
      display: none;
    }

    .control-icons-wrapper:hover .control-icons-filled {
      display: block;
    }

    svg {
      margin-left: 25px;
    }
  }
}

// RTL
body[dir="rtl"] {
  .player__top-controls {
    .player__top-titile-wrapper {
      .title {
        padding-right: 20px;
        padding-left: unset;
      }
      .back-btn {
        transform: rotateZ(180deg);
      }
    }
  }
}

.show-controls {
  opacity: 1 !important;
}

.show-controls-mobile-safari {
  opacity: 1 !important;
  transform: scaleX(1) scaleY(1) scaleZ(1);
}

.thumbnail-wrapper {
  height: 90px;
  width: 160px;
  background: #202020;
  position: absolute;
  bottom: 45px;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(3, 3, 3, 0.1);
  border: solid 2px #ffffff;
  box-sizing: content-box;
  display: none;

  @media only screen and (max-width: 550px) {
    height: 70px;
    width: 120px;
  }
}

.thumbnail-wrapper---open {
  display: block;
  -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.No-player-screen {
  width: 70px;
  height: 70px;

  img {
    position: static !important;
  }

  &:hover {
    cursor: pointer;
  }
}

.player-settings__list---open {
  opacity: 1;
  z-index: 9;

  .player-settings__content-wrapper {
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
    border-radius: 5px;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    padding: 30px 40px 40px 40px;
  }
}

.player-settings__list---close {
  display: none;
}

@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@keyframes slide-top {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }

  100% {
    -webkit-transform: translateY(-25px);
    transform: translateY(-25px);
  }
}

@-webkit-keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes scale-in-center {
  0% {
    -webkit-transform: scale(0.85);
    transform: scale(0.85);
    opacity: 1;
  }

  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}

.spinner-wrapper {
  position: fixed;
  width: 100vw;
  height: 100vh;
  z-index: -1;
  opacity: 0;
  top: 0;
  left: 0;
  display: flex;
  background: rgba(0, 0, 0, 0.6);
  align-items: center;
  justify-content: center;

  .player & {
    position: absolute;
    width: 100%;
    height: 100%;
    transition: all 0.45s ease-in;
  }
}

.player-wrapper {
  top: 0;
  height: 100%;
  z-index: 1;
  position: absolute;
  width: 100%;
  overflow: hidden;

  .spinner-wrapper {
    position: absolute;
    height: 100%;
    width: 100%;
  }

  .player-content {
    width: 100%;

    video {
      width: 100%;
    }
  }
}

.spinner-open {
  z-index: 9;
  opacity: 1;

  .player & {
    z-index: 3;
    transition: all 0.45s ease-in;
  }
}

.spinner-close {
  display: none;
  transition: all 0.3s ease-in;
}

.replay__img-holder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center;
}

.binch-container {
  position: absolute;
  right: 20px;
  bottom: 100px;
  z-index: 6;
  background: #1d1d1d;
  display: none;
  width: 250px;
  border-radius: 4px;
  color: white;

  p {
    font-family: $font-family-secondary;
    font-size: $font-size-secondary + 2px;
    font-weight: 500;
    color: #ffd200;
    margin: 0;
  }

  .binch__header {
    letter-spacing: 2px;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 20px;
  }

  .binch__img {
    width: 100%;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  .binch__timer {
    font-style: normal;
    padding: 5px 10px 15px 20px;
    color: white;
  }

  .binch__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 20px;

    .binch-content__text {
      font-style: normal;
      padding-right: 10px;

      h3 {
        margin: 0;
        display: block;
        font-size: $font-size + 7px;
        word-break: break-word;
      }
    }
    .binch_player-icon {
      display: block;
    }
    .binch_playerfill-icon {
      display: none;
    }

    .binch-content__icon:hover .binch_player-icon {
      display: none;
    }

    .binch-content__icon:hover .binch_playerfill-icon {
      display: block;
    }
  }
}

.binch-container---open {
  display: block;
  -webkit-animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: slide-top 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

.subTitle {
  position: absolute;
  bottom: 50px;
  left: 0;
  margin: 0;
  width: 100%;
  text-align: center;

  p {
    margin: 0 15px;
    padding: 0 3px;
    background: rgba(0, 0, 0, 0.8);
    display: inline-block;
    font-size: $font-size * 2;
    font-weight: 500;
  }
}

.player__audio,
.player__settings,
#fullscreenBtn,
.volume-btn-wrapper {
  text-align: center;
  min-width: 47px;
  margin: 0 13px;
  svg {
    margin-left: 0 !important;
  }

  p {
    color: white;
    font-family: $font-family-secondary;
    font-size: $font-size-secondary + 3px;
    margin-bottom: 4px;
  }
}
// RTL
body[dir="rtl"] {
  .player__audio,
  .player__settings,
  #fullscreenBtn,
  .volume-btn-wrapper {
    svg {
      margin-right: 0 !important;
    }
  }
}

.show-controls + .subTitle {
  bottom: 250px;
}
.player__live_label {
  font-size: 18px;
  font-weight: 500;
  display: flex;
  height: 24px;
  align-items: center;
}
.player__live-icon {
  width: 24px;
  svg {
    height: 17px;
  }
}
.player-portrait {
  // .show-controls + .subTitle {
  //   bottom: 50px;
  // }

  // .subTitle p {
  //   font-size: 16px;
  // }

  .player__top-controls {
    padding: 10px;

    p {
      margin-top: 0;
    }
  }

  .player__bottom-controls {
    padding: 15px;
    padding-bottom: 20px;
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000 50%);

    .controls {
      margin-bottom: 30px;
    }

    .seek-bar .seek-time {
      padding-top: 20px;
    }
  }
}

.player-state-wrapper {
  justify-content: flex-start;
  cursor: text;
  // padding: 10px 40px 30px 40px;

  li {
    text-align: left;
    margin-bottom: 4px;
    font-weight: 400;
    display: flex;
    justify-content: flex-start;
  }

  .label {
    width: 40%;
    color: rgba(166, 166, 166, 1);
  }
  .value {
    width: 60%;
    word-wrap: break-word;
  }

  .option {
    color: rgba(255, 210, 0, 1);
    cursor: pointer;
  }
  .option:hover {
    opacity: 0.8;
  }
}

.player__state-pop__up {
  background: rgba(31, 31, 31, 0.85);
  margin-top: -50px;
  padding: 20px 30px;
  // padding-right: 50px;
  position: fixed;
  right: 4%;
  top: 50%;
  transform: translate(0%, -50%);
  // left: 0;
  // bottom: 0;
  // display: flex;
  // align-items: center;
  // justify-content: flex-end;
  text-align: left;
  display: none;
  z-index: -1;
  transition: all 0.1s ease-in-out;
  width: 70%;
  max-width: 700px;
  // background-color: #2b2b2b;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  .player__state-pop__up-wrapper {
    transition: max-height 0.3s ease-out; // note that we're transitioning max-height, not height!
  }

  .fixed-header {
    // background-color: red;
    margin-bottom: 10px;
    // padding: 5px 10;
  }

  .player__state-pop__up-wrapper {
    width: 100%;
    // background-color: #2b2b2b;
    // border-radius: 5px;
    // box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);
    // padding: 5px 10px;
    padding-top: 10px;
    max-height: 45vh;
    overflow: auto;
  }

  .player__state-pop__up-head {
    display: flex;
    justify-content: space-between;
    position: relative;
    z-index: 9;
    text-align: left;
    // background-color: red;

    & > div {
      overflow: hidden;
    }

    .player-audio__close {
      min-width: 22px;
      margin-left: 15px;
      padding-bottom: 3px;
      transition: all 0.2s ease-in-out;

      &:hover {
        cursor: pointer;
        transform: scale(1.15);
      }
    }

    body[dir="rtl"]{
      .player-audio__close {
        margin-right: 15px;
        margin-left: unset;
      }
    }

    p {
      margin: 0;
      font-size: $font-size + 11px;
      font-weight: 600;
      line-height: 26px;
    }
  }

  @media only screen and (max-width: 900px) {
    width: 50%;
  }

  @media only screen and (max-width: 650px) {
    width: calc(100% - 50px);
  }
}

.player__state-pop__up---open {
  display: block;
  opacity: 1;
  z-index: 10;

  .player__state-pop__up-wrapper {
    -webkit-animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94)
      both;
    animation: scale-in-center 0.2s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  }
}

// .controls[dir="rtl"]{
//   .player__audio,
//   .player__settings,
//   #fullscreenBtn,
//   .volume-btn-wrapper {
//     margin-right: 35px;
//     margin-left: unset;
//     svg {
//       margin-right: 0 !important;
//       margin-left: unset;
//     }
//   }
// }
