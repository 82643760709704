.button-wrapper {
    button {
        font-size: 15px;
        padding: 12px 18px 11px;
        font-weight: 600;
        min-width: 90px;
        border-radius: 5px;
        transition: all .2s ease-in-out;

        &:hover {
            cursor: pointer;
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }
}

.button-sm {
    button {
        font-size: 12px;
        padding: 11px 18px 10px;
    }
}

.default {
    button {
        font-weight: 600;
        background: #ffd200;
        border-radius: 5px;
        color: black;
        transition: all .2s ease-in-out;

        &:hover {
            cursor: pointer;
            outline: none;
        }

        &:focus {
            outline: none;
        }
    }
}