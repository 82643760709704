@import "../../../styles/tools/mixins";
@import "../../../styles/settings/global";
@import "../../../styles/settings/typography";

.toast {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  max-width: 400px;
  width: 100%;
  margin-left: 300px;
  /* Divide value of min-width by 2 */
  text-align: center;
  /* Centered text */
  padding: 16px;
  /* Padding */
  position: fixed;
  /* Add a z-index if needed */
  right: 30px;
  top: 85px;
  border: solid 2px transparent;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  @include respond-to($wide-breakpoint) {
    max-width: 300px;
    width: 100%;
    margin-left: -50px;
    top: auto;
    bottom: 30px;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  .toast___action {
    p,
    a {
      font-weight: 500;
      font-size: 16px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: $font-family-secondary;
      margin: 0;
      font-weight: 500;
      font-size: 14px;
    }
  }
}

body[dir="rtl"]{
  .toast {
    margin-right: 300px;
    margin-left: unset;
    left: 30px;
    right: unset;
  
    @include respond-to($wide-breakpoint) {
      margin-right: -50px;
      margin: 0 auto;
    }
  }
}

.toast___active {
  visibility: visible !important;
  /* Sit on top of the screen */
  z-index: 100;
  /* Add animation: Take 0.5 seconds to fade in and out the toast.
    However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadeinRight 0.5s;
  animation: fadeinRight 0.5s;

  @include respond-to($wide-breakpoint) {
    -webkit-animation: mobilefadein 0.5s;
    animation: mobilefadein 0.5s;
  }
}

// RTL
body[dir="rtl"]{
  .toast___active {
    visibility: visible !important;
    /* Sit on top of the screen */
    z-index: 100;
    /* Add animation: Take 0.5 seconds to fade in and out the toast.
      However, delay the fade out process for 2.5 seconds */
    -webkit-animation: fadeinLeft 0.5s;
    animation: fadeinLeft 0.5s;
  
    @include respond-to($wide-breakpoint) {
      -webkit-animation: mobilefadein 0.5s;
      animation: mobilefadein 0.5s;
    }
  }
}

@-webkit-keyframes fadeinLeft {
  from {left: 0; opacity: 0;}
  to {left: 30px; opacity: 1;}
}

@keyframes fadeinLeft {
  from {left: 0; opacity: 0;}
  to {left: 30px; opacity: 1;}
}

@-webkit-keyframes fadeoutLeft {
  from {left: 30px; opacity: 1;}
  to {left: 0; opacity: 0;}
}

@keyframes fadeoutLeft {
  from {left: 30px; opacity: 1;}
  to {left: 0; opacity: 0;}
}

// RTL
@-webkit-keyframes fadeinRight {
  from {right: 0; opacity: 0;}
  to {right: 30px; opacity: 1;}
}

@keyframes fadeinRight {
  from {right: 0; opacity: 0;}
  to {right: 30px; opacity: 1;}
}

@-webkit-keyframes fadeoutRight {
  from {right: 30px; opacity: 1;}
  to {right: 0; opacity: 0;}
}

@keyframes fadeoutRight {
  from {right: 30px; opacity: 1;}
  to {right: 0; opacity: 0;}
}



@-webkit-keyframes mobilefadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@keyframes mobilefadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes mobilefadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes mobilefadeout {
  from {
    bottom: 30px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}
