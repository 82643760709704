.confirm-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(34, 34, 34, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 6;
  padding: 15px;

  .confirm-popup-header {
    position: relative;
  }

  .confirm-popup-header-close-btn {
    position: absolute;
    right: 15px;
    top: -30px;
    text-align: right;
    padding-bottom: 15px;
    margin-right: -15px;
    svg {
      cursor: pointer;
    }
  }

  .confirm-popup-description{
    margin-top:10px;
  }

  .confirm-popup__content {
    text-align: center;
    border-radius: 4px;
    padding: 25px;
    max-width: 342px;
    width: 100%;

    h2 {
      margin: 0;
      font-size: $font-size + 9px;
    }

    p {
      white-space: normal;
      margin: 10px 0 20px;
      font-family: $font-family-secondary;
    }
  }

  .confirm-popup__btn {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .confirm-popup-wrapper---inline-action & {
      flex-direction: row;
    }

    .popup-btn-subtitle {
      color: #fff;
      font-size: $font-size-secondary + 4px;
      margin-top: 25px;
      font-family: $font-family-secondary;
    }

    .popup-btn {
      padding: 12px 20px 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 700;
      font-size: $font-size + 1px;

      &:hover {
        cursor: pointer;
      }

      svg {
        margin-right: 8px;
      }
    }

    .popup-btn__negative {
      margin-top: 15px;
    }
  }
}

.login_popup {
  .confirm-popup__content {
    max-width: 400px;
  }
  .confirm-popup-header-close-btn {
    top: -21px;
    margin-right: -20px;
  }
}

.two-action-popup-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.75);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: -1;
  opacity: 0;
  padding: 15px;

  .two-action-popup-wrapper__content {
    text-align: center;
    border-radius: 5px;
    max-width: 370px;
    width: 100%;
    max-height: 98vh;
    overflow: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

    .text-box {
      padding: 30px;
    }

    .img-box {
      .img-holder {
        height: 210px;
        width: 100%;
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 5px 5px 0 0;
        display: flex;
        align-items: flex-end;

        .data {
          padding: 25px 15px 15px;
          width: 100%;
          background-image: linear-gradient(to bottom, rgba(43, 43, 43, 0), #000000);

          h2 {
            text-align: left;
            margin-bottom: 4px;
            word-break: break-all;
          }

          .details-section-header__badges {
            ul {
              margin: 0;
            }

            p {
              margin: 0;
            }
          }
        }
      }
    }

    h2 {
      margin: 0;
      font-size: $font-size + 7px;
    }

    p {
      white-space: normal;
      margin: 10px 0 20px;
      font-family: $font-family-secondary;
    }

    .optional {
      margin: 20px 0 10px;
    }
  }
}

.two-action-popup-wrapper---open {
  z-index: 5;
  opacity: 1;
}

.snackbar {
  visibility: hidden;
  /* Hidden by default. Visible on click */
  max-width: 400px;
  width: 100%;
  margin-left: -125px;
  /* Divide value of min-width by 2 */
  background-color: #2b2b2b;
  color: #fff;
  /* White text color */
  text-align: center;
  /* Centered text */
  border-radius: 2px;
  /* Rounded borders */
  padding: 16px;
  /* Padding */
  position: fixed;
  /* Add a z-index if needed */
  right: 30px;
  top: 85px;
  border: solid 2px #ffd200;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  @include respond-to($wide-breakpoint) {
    max-width: 300px;
    width: 100%;
    margin-left: -50px;
  }

  .snackbar___action {
    p,
    a {
      color: #ffd200;
      font-weight: 500;
      font-size: $font-size + 1px;
    }
  }

  .text {
    display: flex;
    align-items: center;
    justify-content: space-between;

    p {
      font-family: $font-family-secondary;
      margin: 0;
      font-weight: 500;
      font-size: $font-size-secondary + 4px;
    }
  }
}

.confirm-popup-wrapper---inline-action {
  .confirm-popup__btn {
    flex-direction: row;

    .popup-btn__negative {
      margin: 0;
      margin-left: 20px;
    }
  }
}

// RTL 
body[dir="rtl"]{
  .confirm-popup-wrapper---inline-action {
    .confirm-popup__btn {
      .popup-btn__negative {
        margin: 0;
        margin-right: 20px;
      }
    }
  }
}

.snackbar___active {
  visibility: visible !important;
  /* Sit on top of the screen */
  z-index: 9;
  /* Add animation: Take 0.5 seconds to fade in and out the snackbar.
  However, delay the fade out process for 2.5 seconds */
  -webkit-animation: fadein 0.5s, fadeout 0.5s 4s;
  animation: fadein 0.5s, fadeout 0.5s 4s;
}

/* Animations to fade the snackbar in and out */
@-webkit-keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 30px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    right: 0;
    opacity: 0;
  }

  to {
    right: 30px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    right: 30px;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    right: 30px;
    opacity: 1;
  }

  to {
    right: 0;
    opacity: 0;
  }
}

.get-app-toast {
  position: fixed;
  left: 0;
  bottom: 0;
  z-index: 6;
  width: 100%;
  padding: 20px;
  box-shadow: 0px 0px 16px 0 rgba(0, 0, 0, 0.25);

  p {
    margin: 0;
    font-size: 16px;
    line-height: 18px;
  }

  .text-box {
    h2 {
      margin: 0;
      padding-bottom: 5px;
      line-height: 18px;
      font-size: 18px;
    }
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-top: 15px;

    .dowload-Btn {
      margin-left: 20px;
    }

    .dismiss-Btn {
      color: #727272;
    }
  }
}

.pop-up-container {
  background-color: rgba(29, 29, 29, 0.75);
  padding: 15px;
  position: fixed;
  right: 0;
  top: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease-in-out;
  width: 100%;
  z-index: 9;
}

.multi-profile-list-popup {
  align-items: flex-end;
  padding-bottom: 0;
}
.kids-pin.pop-up-container {
  background-color: rgba(29, 29, 29, 0.60);

}

.pop-up-container___content-wrapper {
  width: 100%;
  max-width: 750px;
  max-height: 95vh;
  border-radius: 5px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.25);

  .multi-profile-list-popup & {
    border-radius: 5px 5px 0 0;
  }
}

.purchase---pop-up {
  .confirm-popup__content p {
    margin: 15px 0 20px;
  }

  .button-wrapper {
    button {
      width: 240px;
      border-radius: 100px;
      box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.5);
      border: none;

      &:hover,
      &:focus {
        border: none;
      }
    }

  }
}
